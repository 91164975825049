<template>
  <div style="background-color: #070A2D">
    <div>
      <monitor-navigator></monitor-navigator>
    </div>
    <div>
      <router-view></router-view>
      <!--警铃声-->
      <audio id="alarm-audio" loop src="../../assets/media/alarm.mp3" type="audio/mpeg" />
    </div>
    <!--四分屏&九分屏弹窗挂载到最外层-->
    <portal-target name="screen-modal"></portal-target>
  </div>
</template>

<script>
import monitorNavigator from "../../components/MonitorNavigator"
import {getAnalyseData} from '../../api/cameraAnalyse'
import {listResource} from '../../api/resource'
import Vue from 'vue'


let firstClick = true
let timeout = null

export default {
  name: "MonitorIndex",
  components: {
    monitorNavigator
  },
  data(){
    return{
      bodyHeight:"",
      form: this.$form.createForm(this)
    }
  },
  methods:{
    getBodyHeignt(){
      let winHeight;
      if (window.innerHeight){
        winHeight = window.innerHeight;
      }else if ((document.body) && (document.body.clientHeight)){
        winHeight = document.body.clientHeight;
      }
      return winHeight;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.$router.push("/monitorIndex");
        }
      });
    },
    hasAlarm() { // 查询是否有未确认的报警,响铃或停止响铃
      let param = {
        department: '',
        alertType: '',
        startTime: '1970-01-01 00:00:00',
        endTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        deviceName: '',
        fromCompany: '',
        isAlarm: 1,
        isConfirm: 0,
        page: 1,
        'page-size': 1
      }
      getAnalyseData(param).then(res => {
        // 2023-11-30 17:25 update 取消报警响铃
        if (res.data.total > 0) {
          // this.startBell()
        } else {
          // this.stopBell()
        }
      })
    },
    startBell () { // 开始响铃
        let audio = document.getElementById('alarm-audio')
        audio.play()
    },
    stopBell () { // 停止响铃
      let audio = document.getElementById('alarm-audio')
      audio.pause()
    }
  },
  beforeMount:function () {
    this.bodyHeight = this.getBodyHeignt();
  },
  mounted () {
    this.$nextTick(() => {
      // window.onresize = () => {
      //   return (() => {
      //     that.bodyHeight = that.getBodyHeignt();
      //   })()
      // };

      const roleId = Vue.ls.get('user').roleIds[0] // 角色id
      listResource(roleId).then(res => {
        if (res.data.filter(item => item.leve1 === 'event').length > 0) { // 分配了事件资源权限才查询
          this.hasAlarm()

          /*
          setInterval(() => {
            this.hasAlarm()
          }, 60000)
          */

          // 使用setTimeout实现
          clearTimeout(timeout)
          timeout = null
          const timeoutFn = () => {
            timeout = setTimeout(() => {
              this.hasAlarm()
              timeoutFn()
            }, 300000) // 5分钟
          }
          timeoutFn()

          // chrome规定音频必须用户与页面交互之后才能播放
          document.body.addEventListener("click", () => {
            if (firstClick) { // 第一次点击
              this.hasAlarm()
            }
            firstClick = false
          }, false);
        }
      })

      // 当手动确认完事件后,判断一次是否还需继续响铃
      this.$eventBus.$on('refreshBellStatus', () => {
        this.hasAlarm()
      })
    });
  }
}
</script>

<style scoped>

</style>
